import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map, of } from 'rxjs';
import { DatafactoryService } from '../data-factory/data.factory';
import { RequestParams } from '../data-factory/request-param';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';
import { Location } from '@angular/common';

const lookupListsApis = {
    lookupList: (type, searchJson = '{}') => environment.webApi + `Lookup/GetLookupList?lookupTypeReq=${type}&searchJson=${searchJson}`,
    lookupListForOrg: environment.webApi + 'Lookup/GetLookupListForOrg'
};

@Injectable({
    providedIn: 'root',
})
export class LookuplistApiService {
    constructor(private dataFactoryService: DatafactoryService, private requestParams: RequestParams, private alert: SweetAlert, private location: Location) {}

    GetLookupListByTpe(type: string, searchJson: string) {
        if ((type === "FacilitiesbyGroup" || type === "OrgGroupsBasedonAccess") && (history.state.userInfo || history.state.RoundId)) { // For edit round with user who has edit round access.
            const roundId = history.state?.RoundId ? history.state.RoundId : history.state?.userInfo?.ConsultRoundId;
            const searchObj = JSON.parse(searchJson);
            searchObj['roundId'] = roundId;
            searchJson = JSON.stringify(searchObj);
        }
        if(type!='OrguserRoleForOrg' && type!='Wing')
        {
        return this.dataFactoryService
            .GetMethod(lookupListsApis.lookupList(type, searchJson))
            .pipe(map(data => {
                if (data[0].response === 'success') {
                    // When lookupTypeRequest 'OrgGroupsBasedonAccess' & is response data is null, throw error
                    if (type === "OrgGroupsBasedonAccess" && !data[0].responseData)  {
                        this.alert.prompt("info", "Sorry! You don't have a permission to Create Consult Round", 'OK')
                        .then(res => this.location.back());
                    } 
                    return JSON.parse(data[0].responseData);
                }
            }))
        }
        else {
            var searchValue = JSON.parse(searchJson);
            var localselectedOrgGuid = localStorage.getItem('currentRoute')!='facilities' ? (searchValue?.selectedOrgGuid ?  searchValue?.selectedOrgGuid : localStorage.getItem('selectedOrgGuid')) : localStorage.getItem('selectedOrgGuid');
            
            const params = { lookupTypeReq: type, selectedOrgGuid: localselectedOrgGuid};
            return this.dataFactoryService
                .postMethod(lookupListsApis.lookupListForOrg, this.requestParams.ConstructRequest(params))
                .pipe(map(data => {
                    if (data[0].response === 'success') {
                        return JSON.parse(data[0].responseData);
                    }
                }));
        }
    }

    
}