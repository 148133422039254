import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import { environment } from 'environments/environment';

bootstrapApplication(AppComponent, appConfig)
    .catch(err => console.error(err));
    if (environment.production) {
        // Disable all console methods in production
        console.log = console.error = console.warn = console.info = () => {};
      }