import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Navigation, NavigationEnd, Router, RouterLink } from '@angular/router';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { Message } from 'app/layout/common/messages/messages.types';
import { Subject, Subscription, take, takeUntil } from 'rxjs';
import { DynamicFormsComponent } from '../dynamic-forms/dynamic-forms.component';
import { FormGroup } from '@angular/forms';
import { PatientSearchApiService } from 'app/core/patient-search/patient-search.api.service';
import { UserActivityLogModel } from 'app/core/patient-search/user-activity-log.model';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { DocumentsUploadService } from 'app/core/documents-upload/documents-upload.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { DateTimeZonePipe } from 'app/core/pipes/date-time-zone.pipe';

@Component({
    selector       : 'messages',
    templateUrl    : './messages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'messages',
    standalone     : true,
    imports        : [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe, DynamicFormsComponent, ProfilePictureComponent, DateTimeZonePipe],
})
export class MessagesComponent implements OnInit, OnDestroy
{
    @ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
    @ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;
    @ViewChild('dynaForm') dynaForm: DynamicFormsComponent;

    messages: Message[];
    unreadCount: number = 0;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    formJson = [
        {
            "id": "8",
            "field": "AuditModulesId",
            "icon": "",
            "displayName": "Audit",
            "controlType": "select",
            "tooltip": "Filter by module",
            "label": "",
            "value": 0,
            "column": "w-full max-sm:w-full mb-1 px-4",
            "isRequired": false,
            "isDisabled": false,
            "customClass": "flex no-label",
            "lookupTypeReq": "AuditModules",
            "options": []
        }
    ]
    valueObj = {AuditModulesId: 0};
    auditModuleCtrlValCHangesSub: Subscription;
    logs: UserActivityLogModel[] = [];
    profilePicMap = new Map<string, string>();
    activeModuleId: any;
    previoudModuleId;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _messagesService: MessagesService,
        private _patientsSearchApiService: PatientSearchApiService,
        private documentsUploadService: DocumentsUploadService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private router: Router,
        private _navigationService: NavigationService,
            )
    {
        this.router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(ev => {
            if (ev instanceof NavigationEnd) {
              this.getCurrentActiveModuleId();
            }
          });
          this.getCurrentActiveModuleId();
    }

    getCurrentActiveModuleId() {
        // Check if the current route is the parent route
        // Use split to get the path without query parameters
        const pathWithoutQueryParams = this.router.url.split('?')[0];
        this._navigationService._navigation.pipe(takeUntil(this._unsubscribeAll))
        .subscribe((navigation: any[]) => {
            if (navigation?.length) {
                navigation.forEach(nav => {
                    if (nav.children?.length) {
                        nav.children.forEach(childNav => {
                            if (childNav.link?.includes(pathWithoutQueryParams)) {
                                this.activeModuleId = nav.id;
                                if (pathWithoutQueryParams === '/manage-users') {
                                    this.activeModuleId = childNav.id;
                                }
                                if (pathWithoutQueryParams === '/roles') {
                                    this.activeModuleId = 0;
                                }
                            }
                        })
                    }
                    if (nav.link?.includes(pathWithoutQueryParams)) {
                        this.activeModuleId = nav.id;
                    }
                });
                this.valueObj.AuditModulesId = this.activeModuleId ? this.activeModuleId : 0;
            }
        });
    }

    formInitializedEmt(fg: FormGroup): void {
       if (fg) {
        const auditModulesCtrl = fg.get('AuditModulesId');
        if (auditModulesCtrl) {
            if (this.auditModuleCtrlValCHangesSub) this.auditModuleCtrlValCHangesSub.unsubscribe();
           this.auditModuleCtrlValCHangesSub = auditModulesCtrl.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(v => {            
            if((v || v == 0) && this.previoudModuleId != v) {
            this.previoudModuleId = v;
            this.activeModuleId = v;
            this._patientsSearchApiService.GetUserActivityLog({moduleId: v?.toString()})
            .subscribe(res => {
                this.logs = res;
                // this.logs.forEach(async log => {
                //     // FOr faster and light way of handling n nnumer of downloads for porcile pic umage of each user,
                //     // Instead of downloading the blob, get the constructed profile pic url from API or else construct the path from FE only with the keys,
                //     // and the keys are only allowed for profile pic container and for this particular logged in user only.
                //     if (log.FileName && !this.profilePicMap.has(log.FileName)) {
                //          // Create a blob URL from the received blob
                //         const blob = await this.documentsUploadService.downloadProfileImage('', log.OrgUserGuid, log.FileName).toPromise();
                //         this.profilePicMap.set(log.FileName, URL.createObjectURL(blob));
                //     }
                //     log.profilePic = this.profilePicMap.get(log.FileName);
                // })
            });
        }
            });
        }
       }
    }

    getMessages() {
        this._patientsSearchApiService.GetUserActivityLog({moduleId: this.activeModuleId ? this.activeModuleId?.toString() : '0'})
            .subscribe(res => {
                this.logs = res;
                // this.logs.forEach(async log => {
                //     // FOr faster and light way of handling n nnumer of downloads for porcile pic umage of each user,
                //     // Instead of downloading the blob, get the constructed profile pic url from API or else construct the path from FE only with the keys,
                //     // and the keys are only allowed for profile pic container and for this particular logged in user only.
                //     if (log.FileName && !this.profilePicMap.has(log.FileName)) {
                //          // Create a blob URL from the received blob
                //         const blob = await this.documentsUploadService.downloadProfileImage('', log.OrgUserGuid, log.FileName).toPromise();
                //         this.profilePicMap.set(log.FileName, URL.createObjectURL(blob));
                //     }
                //     log.profilePic = this.profilePicMap.get(log.FileName);
                // })
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to message changes
        this._messagesService.messages$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((messages: Message[]) =>
            {
                // Load the messages
                this.messages = messages;

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the messages panel
     */
    openPanel(): void
    {
        // Return if the messages panel or its origin is not defined
        if ( !this._messagesPanel || !this._messagesOrigin )
        {
            return;
        }

        // Create the overlay if it doesn't exist
        if ( !this._overlayRef )
        {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
        this.getMessages();
    }

    /**
     * Close the messages panel
     */
    closePanel(): void
    {
        this._overlayRef.detach();
    }

    /**
     * Mark all messages as read
     */
    markAllAsRead(): void
    {
        // Mark all as read
        this._messagesService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given message
     */
    toggleRead(message: Message): void
    {
        // Toggle the read status
        message.read = !message.read;

        // Update the message
        this._messagesService.update(message.id, message).subscribe();
    }

    /**
     * Delete the given message
     */
    delete(message: Message): void
    {
        // Delete the message
        this._messagesService.delete(message.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void
    {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : 'fuse-backdrop-on-mobile',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX : 'start',
                        originY : 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX : 'start',
                        originY : 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX : 'end',
                        originY : 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX : 'end',
                        originY : 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() =>
        {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;

        if ( this.messages && this.messages.length )
        {
            count = this.messages.filter(message => !message.read).length;
        }

        this.unreadCount = count;
    }
}
