import { Injectable } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpErrorResponse,
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse
} from '@angular/common/http';
import { tap, catchError, finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject, of, throwError } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { SweetAlert } from '@fuse/components/sweet-alerts/sweet-alert';
import moment from 'moment';
import { SharedService } from './SharedService';

@Injectable({
  providedIn: "root",
})
export class DatafactoryService implements HttpInterceptor {
  
  url: any;
  token: any;
  public totalRequests = 0;
  isOffline: boolean;
  showAlert = false;
  private pendingRequests: Subject<void>[] = [];

  constructor(
    public _httpClient: HttpClient, private sweetAlerts: SweetAlert,
    private router: Router, private sharedService: SharedService
  ) {
    // Cancel all the pending requests on navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.cancelPendingRequests();
      }
    });
  }

  cancelPendingRequests(): void {
    this.pendingRequests.forEach(request => {
      request.next();
      request.complete();
    });
    this.pendingRequests = [];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // this.loaderService.show();
    this.totalRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests < 0) {
          this.totalRequests = 0;
        }
        if (this.totalRequests === 0) {
        //   this.loaderService.hide();
        }
      })
    );
  }

  postMethod(url: string, params?, options?) {
    const cancelRequest = new Subject<void>();
    this.pendingRequests.push(cancelRequest);

    this.token = sessionStorage.getItem("APPUSERTK");
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json;charset=utf-8")
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    return this._httpClient
      .post(url, params, { headers: headers, ...options })
      .pipe(
        takeUntil(cancelRequest),
        tap((data: any) => {
          return data;
        }), 
        catchError(this.catchHttpError.bind(this))
        );
  }
  postAnonymousMethod(url: string, params?, options?) {
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      .set("Content-Type", "application/json;charset=utf-8")
      .set("Access-Control-Allow-Origin", "*");
    return this._httpClient
      .post(url, params, { headers: headers, ...options })
      .pipe(
        tap((data: any) => {
          if (
            !!data?.length &&
            data[0].responseCode === 3 &&
            (data[0].response === "Failure" || data[0].response === "failure")
          ) {
           
            this.sweetAlerts.prompt("success", data[0]?.message , "OK");
            // Show the alert
            this.showAlert = true;
          }
          return data;
        }),
        catchError(this.catchHttpError.bind(this))
      );
  }
  catchHttpError(error: HttpErrorResponse) {
   // const time = this.datePipe.transform(new Date(), "dd-MMM-yy h:mm a");
    this.totalRequests = 0;
    
    const errorResponse = Array.isArray(error.error) ? error.error[0] : error.error;
    // if (this.appStateService.isOffline || error.status === 0) {
    //   this.alertService.prompt("error",
    //   "<h6>Sorry! No Internet connection<br>Please try again.<br> If issue still persist please contact your ISP.<br><br>" +
    //     time +
    //     "<br><br>Error code: " +
    //     "net::ERR_INTERNET_DISCONNECTED" +
    //     "</h6>",
    //   "OK");
    //   return;
    // }
    console.log(error, error.status, error.statusText)
    if (error.status === 401) {
      this.sharedService.clearLocalStorageExcept(['TenantGuid', 'TenantDetails', 'TimeZone']);
      this.router.navigateByUrl("/auth/login");
      return;
    } else if (
      error.status === 400 &&
      errorResponse.responseCode === 3 &&
      errorResponse?.response?.toLowerCase() === "failure"
    ) {
      this.sweetAlerts.prompt("error", errorResponse.message  + "<br><br>" + this.formatDateTime(new Date()) , "Ok");
    } else if (error.status === 400 &&
      error.headers.get("message")
      ) {
        this.sweetAlerts.prompt("error", error.headers.get("message")  + "<br><br>" + this.formatDateTime(new Date()), "ok");
      
    }
    else if (error.status === 429) {
      this.sweetAlerts.prompt(
        "warning",
        "<h6>Sorry! Too many requests, please try again<br><br>" +
        //  time +
          "<br><br>Error code: " +
          error.status  + "<br><br>" + this.formatDateTime(new Date()) +
          "</h6>",
        "OK"
      );
    } else {
      if (!navigator.onLine) { //  If related to no internet connectivity. Will be handled at app.comontnt.ts by showing toast msg.
        return;
      }
      if (error.status === 0) {
        this.sweetAlerts.prompt(
          "warning",
          "<h6>Sorry! An unknown error occurred<br>Please try again.<br> Issue has been noted with our Technical Support team.<br>" +
          //  time +
          "<br>Error code: " +
          error.status + "<br><br>" + this.formatDateTime(new Date()) + "<br><br>" + 
          "</h6>",
          "Try again"
        ).then(data => {
          if(data.isConfirmed) {
            window.location.reload();
          }
        });
      } else {
        this.sweetAlerts.prompt(
          "warning",
          "<h6>Sorry! An unknown error occurred<br>Please try again.<br> Issue has been noted with our Technical Support team.<br><br>" +
          //  time +
          "<br><br>Error code: " +
          error.status  + "<br><br>" + this.formatDateTime(new Date()) +
          "</h6>",
          "OK"
        )
      }
    }
    //return throwError("post Catch" + error);
}

formatDateTime(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const amPm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedHours = String(hours).padStart(2, '0');

  return `${day}-${month}-${year} ${formattedHours}:${minutes} ${amPm}`;
}
  // Get Method
  GetMethod(url: string) {
    return this._httpClient.get(url);
  }

  getJSON(path: string): Observable<any> {
    return this._httpClient.get(path);
  }

  uploadFiles(url: string, params?, options?) {

    this.token = sessionStorage.getItem("APPUSERTK");
    const headers = new HttpHeaders()
      .set("Accept", "application/json")
      // .set('Content-Type', 'multipart/form-data')
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    return this._httpClient
      .post(url, params, { headers: headers, ...options })
      .pipe(
        tap((data: any) => {
          return data;
        }), 
        catchError(this.catchHttpError.bind(this))
        );
  }

  // For GET method
  downloadFile(url: string) {
    const cancelRequest = new Subject<void>();
    this.pendingRequests.push(cancelRequest);

    this.token = sessionStorage.getItem("APPUSERTK");
    const headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    return this._httpClient
      .get(url, { responseType: 'blob' as 'json', headers })
      .pipe(
        takeUntil(cancelRequest),
        tap((data: any) => {
          return data;
        }), 
        catchError(this.catchHttpError.bind(this))
        );
  }

  // For POST method
  public requestBlob(url: string, params) {
    const cancelRequest = new Subject<void>();
    this.pendingRequests.push(cancelRequest);

    this.token = sessionStorage.getItem("APPUSERTK");
    const headers = new HttpHeaders()
      .set("Access-Control-Allow-Origin", "*")
      .set("Authorization", "Bearer " + this.token);
    return this._httpClient.post(url, params, {
      headers: headers,
      responseType: "arraybuffer",
      observe: "response",
    }).pipe(
        takeUntil(cancelRequest),
      catchError((this.catchHttpError.bind(this)))
    )
  }

  public _downloadFile(httpResponse: HttpResponse<ArrayBuffer>) {
    const blob = new Blob([httpResponse.body]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    const downloadName = httpResponse.headers.get("download-file-name");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      downloadName ? downloadName.replace("date_time", moment().format("dd_MM_yyyy_hh_mm_a")) : "ACDP-excel-download.xlsx"
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.sweetAlerts.showToast("success", "File downloaded successfully!", 3600);

  }
}
